<template>
  <div>
    <mailchimp-account-add
      :is-add-new-mailchimp-account-sidebar-active.sync="
        isAddNewMailchimpAccountSidebarActive
      "
      :region-options="regionOptions"
      @refetch-data="refreshTable"
    />

    <mailchimp-account-update
      :is-update-mailchimp-account-sidebar-active.sync="
        isUpdateMailchimpAccountSidebarActive
      "
      :region-options="regionOptions"
      :mail-chimp-data="currentDataAccount"
      @refetch-data="refreshTable"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('mail_create')"
                variant="primary"
                @click="isAddNewMailchimpAccountSidebarActive = true"
              >
                <span class="text-nowrap">Add account</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refMailchimpAccount"
          class="position-relative"
          :items="fetchAccountMailchimp"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Busy state -->
          <!-- <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template> -->
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link v-if="$can('mail_create')" @click="showModal(data.item)">
              Edit
            </b-link>
            <b-link v-if="$can('mail_create')" @click="Sync(data.item.id)">
              Sync
            </b-link>
            <b-link v-if="$can('mail_delete')">
              Delete
            </b-link>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(createdAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <template #cell(regionId)="data">
            <span class="text-capitalize">
              {{ data.item.regionId.toUpperCase() }}
            </span>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAccounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import store from "@/store";

import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "@/components/userManager/UsersListFilters.vue";
import ActionSelect from "@/components/userManager/ActionSelect.vue";
// eslint-disable-next-line import/no-cycle
import MailchimpAccountAdd from "@/components/mailchimp/account/mailchimpAccountAdd.vue";
import MailchimpAccountUpdate from "@/components/mailchimp/account/mailchimpAccountUpdate.vue";

import UserAssignRole from "@/components/userManager/UserAssignRole.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line import/no-cycle
import { mapGetters } from "vuex";
import { formatDate } from "../../../utils/utils";
import useMailchimpAccount from "./useMailchimpAccount";

export default {
  components: {
    BOverlay,
    MailchimpAccountUpdate,
    MailchimpAccountAdd,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
    BSpinner,
  },
  setup() {
    // Use toast
    const toast = useToast();
    const isAddNewMailchimpAccountSidebarActive = ref(false);
    const isUpdateMailchimpAccountSidebarActive = ref(false);

    const loadingTable = ref(false);

    const {
      timeout,
      showTable,
      fetchAccountMailchimp,
      tableColumns,
      perPage,
      currentPage,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailchimpAccount,
      refetchData,
    } = useMailchimpAccount();
    return {
      timeout,
      showTable,
      toast,
      formatDate,
      // Sidebar
      isAddNewMailchimpAccountSidebarActive,
      isUpdateMailchimpAccountSidebarActive,
      fetchAccountMailchimp,
      tableColumns,
      perPage,
      currentPage,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailchimpAccount,
      refetchData,
      loadingTable,
    };
  },
  data() {
    return {
      currentDataAccount: {
        name: "",
        key: "",
        regionId: "",
      },
      modalShow: false,
      newRole: null,
      nameState: null,
    };
  },
  computed: {
    ...mapGetters("app", ["regionOptions"]),
  },
  methods: {
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 2000);
    },
    Sync(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to sync template this account.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            store.dispatch("mailchimp/syncMailchimpAccount", id).then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sync account successfully",
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });
              this.refreshTable();
            });
          }
        })
        .catch((e) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sync error",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showModal(data) {
      this.currentDataAccount = data;
      this.isUpdateMailchimpAccountSidebarActive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
