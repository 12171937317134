<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAssignRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-assign-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Assign Role
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @reset.prevent="resetForm"
        >

          <!-- First Name -->
          <b-form-group
            v-if="userData.role"
            label="Current Role"
            label-for="first-name"
          >
            <b-form-input
              id="first-name"
              v-model="userData.role.name"
              autofocus
              trim
              readonly
            />
          </b-form-group>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="New Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newRole"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="validation()"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAssignRoleSidebarActive',
    event: 'update:is-assign-role-sidebar-active',
  },
  props: {
    isAssignRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  methods: {
    validation() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const blankNewRole = {}
    const newRole = ref(JSON.parse(JSON.stringify(blankNewRole)))
    const resetuserData = () => {
      newRole.value = ref(JSON.parse(JSON.stringify(blankNewRole)))
    }

    const onSubmit = () => {
      const payload = {
        email: props.userData.email,
        region: props.userData.region,
        title: props.userData.title,
        firstName: props.userData.firstName,
        lastName: props.userData.lastName,
        jobTitle: props.userData.jobTitle,
        roleId: newRole.value,
        productConfig: props.userData.productConfig,
      }
      store.dispatch('user/updateUser', { id: props.userData.id, user: payload })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Assign role success',
              icon: 'AlertTriangleIcon',
              variant: 'sucess',
            },
          })
          emit('refetch-data')
          emit('update:is-assign-role-sidebar-active', false)
        }).catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      toast,
      newRole,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
