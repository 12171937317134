import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import {
  title,
} from '@core/utils/filter'
// Notification
import {
  useToast,
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMailchimpAccount() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refMailchimpAccount = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      sortable: false,
    },
    {
      key: 'regionId',
      label: 'region',
      sortable: false,
      formatter: title,

    },
    {
      key: 'createdAt',
      sortable: false,
    },
    {
      key: 'key',
      sortable: false,
      formatter: title,
    },
    {
      key: 'actions',
    },
  ]
  const perPage = ref(10)
  const totalAccounts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refMailchimpAccount.value ? refMailchimpAccount.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAccounts.value,
    }
  })

  const refetchData = () => {
    refMailchimpAccount.value.refresh()
  }
  const fetchAccountMailchimp = (ctx, callback) => {
    store
      .dispatch('mailchimp/fetchAccountMailchimp')
      .then(response => {
        callback(response.body)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch([currentPage, perPage, searchQuery], () => {
    showTable.value = true
         clearTimeout(timeout.value)

    timeout.value = setTimeout(() => {
      refetchData()
      showTable.value = false
    }, 2000)
  })

  return {
    timeout,
    showTable,
    fetchAccountMailchimp,
    tableColumns,
    perPage,
    currentPage,
    totalAccounts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMailchimpAccount,
    refetchData,
  }
}
