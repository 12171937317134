<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Action Select
      </h5>
    </b-card-header>
    <b-card-body>

      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >

          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="outline-primary">
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="text-nowrap"
                @click="resetAllPassword"
              >Reset all
                password</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="primary"
            >
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="text-nowrap"
                @click="showMsgBoxTwo"
              >Reset
                password</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="success"
              @click=""
            >
              <span class="text-nowrap">Reset device</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="warning"
              @click=""
            >
              <span class="text-nowrap">Continue as</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="secondary"
              @click=""
            >
              <span class="text-nowrap">Show activity</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BModal, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  setup() {
    return {
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCardText,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {

  },
  methods: {
    resetAllPassword() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to reset all store user’s passwords?', {
          title: 'Reset all password',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$emit('reset-all-pass')
          }
        })
    },
    showMsgBoxTwo() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to reset password. The new password will be sent to user email?', {
          title: 'Reset password',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$emit('reset-pass')
          }
        })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
