<template>
  <b-sidebar
    id="add-update-mailchimp-account-sidebar"
    :visible="isUpdateMailchimpAccountSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-update-mailchimp-account-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Update mailchimp account
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!--  Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="mailChimpData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="PwC Marketplace MY"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Region -->
          <validation-provider
            #default="validationContext"
            name="Region"
            rules="required"
          >
            <b-form-group
              label="Region"
              label-for="account-region"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="mailChimpData.regionId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regionOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="account-region"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Key -->
          <validation-provider
            #default="validationContext"
            name="Key"
            rules="required"
          >
            <b-form-group
              label="Key"
              label-for="key"
            >
              <b-form-input
                id="key"
                v-model="mailChimpData.key"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUpdateMailchimpAccountSidebarActive',
    event: 'update:is-update-mailchimp-account-sidebar-active',
  },
  props: {
    isUpdateMailchimpAccountSidebarActive: {
      type: Boolean,
      required: true,
    },
    regionOptions: {
      type: Array,
      required: true,
    },
    mailChimpData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const blankMailchimpAccount = {
      name: '',
      key: '',
      regionId: '',
    }
    const mailchimpAccount = ref(JSON.parse(JSON.stringify(blankMailchimpAccount)))
    const resetAccountData = () => {
      mailchimpAccount.value = JSON.parse(JSON.stringify(blankMailchimpAccount))
    }

    const onSubmit = () => {
      const tranferData = {
        name: props.mailChimpData.name,
        regionId: props.mailChimpData.regionId,
        key: props.mailChimpData.key,
      }
      store
        .dispatch('mailchimp/updateMailchimpAccount', {
          accountID: props.mailChimpData.id,
          account: tranferData,
        })
        .then(() => {
          emit('update:is-update-mailchimp-account-sidebar-active', false)
          emit('refetch-data')

        })
        .catch(() => {
          emit('update:is-update-mailchimp-account-sidebar-active', false)
          emit('refetch-data')

        })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetAccountData,
    )

    return {
      toast,
      mailchimpAccount,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-update-mailchimp-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
